import { useEffect, useState } from "react";
import useForm from "../../../hooks/useForm";
import { styled } from "@mui/material/styles";
import { Button, FormControl, MenuItem, Modal, Autocomplete, Select, TextField, InputLabel, Checkbox, FormControlLabel, Radio, RadioGroup, FormHelperText } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import RenderInput from "../../../utils/RenderInput";
import { getCall } from "../../../Api/axios";
import cogoToast from "cogo-toast";
import moment from "moment";
import dayjs from 'dayjs';

const CssTextField = styled(TextField)({
  "& .MuiInputBase-input": {
    fontSize: "14px",
  },
});
const OfferInfo = ({ formData, onFormUpdate, offerInfoFormErrors, setOfferType, offerType, isEdit, items }) => {
  const { formValues, setFormValues, errors, setErrors } = useForm({
    ...formData,
  });

  const [products, setProducts] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    if (Object.keys(formValues).length === 0 && isEdit) {
      setFormValues(formData)
    }
    onFormUpdate(formValues)
    setSelectedOptions(formValues?.items ?? [])
  }, [formValues, isEdit]);

  useEffect(() => {
    setErrors(offerInfoFormErrors);
  }, [offerInfoFormErrors]);

  const image = {
    id: "images",
    title: "Images (Select minimum 3 files with maximum size of 2Mb for each file)",
    type: "upload",
    multiple: true,
    file_type: "offer_image",
    fontColor: "#000000",
    required: false,
  }

  const renderDatePicker = (key, label) => {
    return (
      <div style={{ marginLeft: "10px" }}>
        <DatePicker
          format="DD/MM/YYYY"
          views={["year", "month", "day"]}
          label={label}
          value={formValues[key] ? dayjs(formValues[key]) : null} // Convert to dayjs object if not null
          onChange={(date) =>
            setFormValues({
              ...formValues,
              [key]: date ? dayjs(date).toISOString() : null // Convert dayjs object to ISO String
            })
          }
          slotProps={{
            textField: {
              error: !!errors[key],
              helperText: errors[key]
            }
          }}
        />
      </div>
    );
  };

  const searchProducts = async (event) => {
    try {
      const inputValue = event.target.value;
      const allProductsURL = `/api/v1/offers/offerProducts?name=${encodeURIComponent(inputValue)}`;
      let products_res = await getCall(allProductsURL);
      let all_products = products_res.map((product) => {
        return { id: product._id, name: (product.productName + " (" + product.productCode + ")") };
      });

      // Extract ids of already selected products if formValues and items are defined
      const selectedProductIds = formValues?.items?.map((item) => item.id) || [];

      // Filter out the selected products from the search results
      const filteredProducts = all_products.filter((product) => !selectedProductIds.includes(product.id));

      setProducts(filteredProducts);
    } catch (error) {
      cogoToast.error(error.message);
    }
  }

  const handleSelect = (event, value) => {
    setSelectedOptions(value);
    setFormValues({ ...formValues, items: value.map(item => ({ id: item.id, name: item.name })) });
  };

  return (
    <div>
      <div className="py-1 flex flex-col">
        <label
          className="text-sm py-2 ml-1 font-medium text-left text-[#606161] inline-block"
        >
          Offer Type: <span className="text-[#ff0000]">*</span>
        </label>
        <FormControl>
          <Select
            size="small"
            disabled={isEdit}
            displayEmpty
            value={formValues.type ?? offerType}
            className="w-full"
            onChange={(e) => {
              setOfferType(e.target.value); // Update the offerType state
            }}
            renderValue={(value) => {
              if (!value) {
                return <p>Select Offer Type</p>;
              }
              return value;
            }}
            MenuProps={{ disableScrollLock: true}}
          >
            <MenuItem value="discount">Discount</MenuItem>
            <MenuItem value="buyXgetY">buyXgetY</MenuItem>
            <MenuItem value="freebie">Freebie</MenuItem>
            <MenuItem value="delivery">Delivery</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div>
        <label
          className="text-sm py-2 ml-1 font-medium text-left text-[#606161] inline-block"
        >
          Offer id: <span className="text-[#ff0000]">*</span>
        </label>
        <CssTextField
          required
          type="input"
          className="w-full h-full px-2.5 py-3.5 text-[#606161] bg-transparent !border-black"
          size="small"
          autoComplete="off"
          placeholder={"Enter Offer Id"}
          error={!!errors.offerId}
          helperText={errors.offerId}
          value={formValues.offerId}
          onChange={(e) =>{
              const regex = /^[a-zA-Z0-9]*$/;
              const filteredText = e.target.value.split('').filter(l=>regex.test(l)).join('').toUpperCase();
              setFormValues({
                ...formValues,
                offerId: filteredText
              });
            }
          }
          inputProps={{
            maxLength:20
          }}
        />
      </div>
      <div className="py-1 flex flex-col">
        <label
          className="text-sm py-2 ml-1 font-medium text-left text-[#606161] inline-block"
        >
          Description: <span className="text-[#ff0000]">*</span>
        </label>
        <CssTextField
          required
          type={"input"}
          className="w-full h-full px-2.5 py-3.5 text-[#606161] bg-transparent !border-black"
          size="small"
          autoComplete="off"
          placeholder={"Enter Description"}
          error={!!errors.description}
          helperText={errors.description}
          value={formValues.description}
          onChange={(e) =>
            setFormValues({
              ...formValues,
              description: e.target.value
            })
          }
        />
      </div>
      <div className="py-1 flex flex-col">
        <label
          className="text-sm py-2 ml-1 font-medium text-left text-[#606161] inline-block"
        >
          Auto Apply: <span className="text-[#ff0000]">*</span>
        </label>
        <FormControl error={!!errors.autoApply}>
          <RadioGroup
            className="w-full h-full px-2.5 text-[#606161] bg-transparent !border-black"
            name="controller=radio-group"
            value={formValues.autoApply === "true" ? true : formValues.autoApply === "false" ? false : null}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                autoApply: e.target.value === "true" ? "true" : "false"
              })
            }
            >
            <div>
              <FormControlLabel
                value="true"
                control={<Radio size="small" />}
                label={<div className="text-sm font-medium text-[#606161]">Yes</div>}
                />
              <FormControlLabel
                value="false"
                control={<Radio size="small" />}
                label={<div className="text-sm font-medium text-[#606161]">No</div>}
                />
            </div>
          </RadioGroup>
          {errors.autoApply && <FormHelperText>{errors.autoApply}</FormHelperText>}
        </FormControl>
      </div>
      <div className="py-1 flex flex-col">
        <label
          className="text-sm py-2 ml-1 font-medium text-left text-[#606161] inline-block"
        >
          Additive: <span className="text-[#ff0000]">*</span>
        </label>
        <FormControl error={!!errors.additive}>
          <RadioGroup
            className="w-full h-full px-2.5  text-[#606161] bg-transparent !border-black"
            value={formValues.additive === "true" ? true : formValues.additive === "false" ? false : null}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                additive: e.target.value === "true" ? "true" : "false"
              })
            }
          >
            <div
            >
              <FormControlLabel
                value="true"
                control={<Radio size="small" />}
                label={<div className="text-sm font-medium text-[#606161]">Yes</div>}
              />
              <FormControlLabel
                value="false"
                control={<Radio size="small" />}
                label={<div className="text-sm font-medium text-[#606161]">No</div>}
              />
            </div>
          </RadioGroup>
          {errors.additive && <FormHelperText>{errors.additive}</FormHelperText>}
        </FormControl>
      </div>
      <div className="py-1 flex flex-col">
        <label
          className="text-sm py-2 ml-1 font-medium text-left text-[#606161] inline-block"
        >
          Validity: <span className="text-[#ff0000]">*</span>
        </label>
          <FormControl error={!!errors.validity} >
        <div style={{ marginBottom: "7px", display: "flex" }} className="pt-2.5">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            {renderDatePicker("validFrom", "Valid From")}
            {renderDatePicker("validTo", "Valid To")}
          </LocalizationProvider>
        </div>
          {(errors.validity) && <FormHelperText>{errors.validity}</FormHelperText>}
          </FormControl>
      </div>
      <RenderInput
        item={{
          ...image,
          error: errors?.[image?.id] ? true : false,
          helperText: errors?.[image.id] || "",
        }}
        state={formValues}
        stateHandler={setFormValues}
        key={image?.id}
      />
      <div className="py-1 flex flex-col">
        <label
          className="text-sm py-2 ml-1 font-medium text-left text-[#606161] inline-block"
        >
          Items:
        </label>
        <FormControl>
          <Autocomplete
            multiple
            options={products}
            size="small"
            getOptionLabel={(option) => option.name}
            value={selectedOptions}
            onChange={handleSelect}
            disabled={offerType==="delivery"}
            filterOptions={(options, { inputValue }) => {
              const selectedValues = selectedOptions.map((selectedOption) => selectedOption.id);
              return products.filter((option) => !selectedValues.includes(option.id));
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Search Products"
                variant="outlined"
                onChange={searchProducts}
                onFocus={searchProducts}
                // error={!!errors.items}
              />
            )}
          />
          <FormHelperText>Can be left empty if offer applicable on all items</FormHelperText>
        </FormControl>
      </div>
    </div>
  )
}

export default OfferInfo;
