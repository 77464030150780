import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { convertDateInStandardFormat } from "../../../utils/formatting/date.js";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import UpdateDialog from "./SettlementRowUpdateDialog.jsx";
import { useState } from "react";
import TableActionsComponent from "../../Shared/TableActionsComponent.jsx";

const StyledTableCell = styled(TableCell)({
  "&.MuiTableCell-root": {
    fontWeight: "bold",
  },
});
export const counterpartyReconStatusMap = {
  "01": "Verified (Paid)",
  "02": "Verified (Overpaid)",
  "03": "Verified (Underpaid)",
  "04": "Verified (Not Paid)",
}
export const orderReconStatusMap = {
  "01": "Provisional",
  "02": "Finale",
  "03": "Deemed Settled"
}

export default function SettlementTable(props) {
  const [showDialog, setShowDialog] = useState(false);
  const [updateData, setUpdateData] = useState();
  const theme = useTheme();
  const navigate = useNavigate();
  const { page, rowsPerPage, totalRecords, handlePageChange, handleRowsPerPageChange, columns, data, onUpdateComplete } = props;
  const onPageChange = (event, newPage) => {
    handlePageChange(newPage);
  };
  const onRowsPerPageChange = (event) => {
    handleRowsPerPageChange(parseInt(event.target.value, 10));
    handlePageChange(0);
  };
  const toggleDialog = () => {
    setShowDialog(!showDialog);
  };
  const renderColumn = (row, column) => {
    switch (column.id) {
      case "provider_store":
        return <span>{row.providerStoreName}</span>;
      case "orderId":
        return <span>{row.settlementOrder.orderId}</span>;
      case "orderDate":
        return <span>{convertDateInStandardFormat(row.settlementOrder.createdAt)}</span>;
      case "orderStatus":
        return <span>{row.settlementOrder.state}</span>;
      case "buyerPrice":
        return <span>{('₹'+row.settlementOrder.payment.params.amount)}</span>;
      case "paymentType":
        return <span>{row.settlementOrder.payment.type}</span>;
      case "refundAmount":
        return <span>{row.settlementOrder.refundAmount>0 ? row.settlementOrder.refundAmount : 'NA'}</span>;
      case "updatedAt":
        return <span>{convertDateInStandardFormat(row.settlementOrder.updatedAt)}</span>;
      case "receiverReconStatus":
        return <span>{row.counterpartyReconStatus ? counterpartyReconStatusMap[row.counterpartyReconStatus] : 'Not Received'}</span>;
      case "bapSettledAmount":
        return <span>{row.buyerSettledAmount ? "₹"+row.buyerSettledAmount : '-'}</span>;
      case "sellerTotalConsideration":
        return <span>{"₹"+row.sellerTotalConsideration}</span>;
      case "tdsDeduction":
        return <span>{'₹'+row.tdsDeduction}</span>;
      case "tcsDeduction":
        if(row.tcsDeduction.igst){
          return <span>IGST: ₹{row.tcsDeduction.igst}</span>;
        } else {
          return <div><p>CGST: ₹{row.tcsDeduction.cgst}</p><p>SGST: ₹{row.tcsDeduction.sgst}</p></div>;
        }
      case "sellerSettlementAmount":
        return <span>{'₹'+row.settlementAmountToSeller}</span>;
      case "sellerSettlementStatus":
        return <span>{row.sellerSettlementStatus.split('').map((letter, i)=>i==0 ? letter.toUpperCase(): letter).join('')}</span>;
      case "action":
        return <div onClick={(e)=>e.stopPropagation()}>
          <Button color="tertiary" size="small" onClick={()=>{setUpdateData({buyerSettledAmount:row.buyerSettledAmount, sellerSettlementStatus: row.sellerSettlementStatus, id:row._id}); setShowDialog(!showDialog)}}
          style={{fontWeight: "700"}}
          >Update Settlement</Button>
        </div>;
      default:
        return null;
    }
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      {showDialog && updateData && <UpdateDialog closeDialog={toggleDialog} open={showDialog} inputData={updateData} onUpdateComplete={onUpdateComplete}/>}
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                }}
                className="font-medium"
                >
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index} hover role="checkbox" tabIndex={-1}>
                {columns.map((column, idx) => (
                  <TableCell key={idx} align={column.align}  onClick={() => {
                    navigate(`/settlement-details/${row._id}`);
                  }}>
                    {renderColumn(row, column)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        ActionsComponent={TableActionsComponent}
      />
    </Paper>
  );
}
