import { CircularProgress } from "@mui/material";

export const loadingView = (
    <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      backgroundColor: "#fff",
      }}
    >
      <CircularProgress />
    </div>
  );