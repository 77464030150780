import { IconButton } from "@mui/material";
import FirstPageRoundedIcon from '@mui/icons-material/FirstPageRounded';
import LastPageRoundedIcon from '@mui/icons-material/LastPageRounded';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';

const TableActionsComponent = ({ count, page, rowsPerPage, onPageChange }) => {
  return (
    <div className="flex ml-2">
            <IconButton
            onClick={(e)=>onPageChange(e,0)}
            color='secondary'
            disabled={page===0}>
              <FirstPageRoundedIcon />
            </IconButton>
            <IconButton
            onClick={(e)=>onPageChange(e,page-1)}
            color='secondary'
            disabled={page===0}>
              <ChevronLeftRoundedIcon />
            </IconButton>
            <form
              onSubmit={(e)=>{
                e.preventDefault();
                const formData = new FormData(e.currentTarget);
                const formObj = Object.fromEntries(formData);
                const selectedPage = parseInt(formObj.page);
                if(!isNaN(selectedPage) && selectedPage >= 1
                && selectedPage <= Math.ceil(count/rowsPerPage)
                && selectedPage != page+1)
                  {
                    onPageChange(e, selectedPage-1);
                  }
              }}
              className="flex items-center">
              <input
                defaultValue={page+1}
                name="page"
                onFocus={(e)=>e.target.select()}
                onBlur={(e)=>{
                  if(!e.target.value){
                    e.target.value = page+1
                  }
                }}
                className="rounded-lg text-center w-12 h-8 poppins"
                style={{
                  outline:`1px solid black`
                }}
                autoComplete="off"
              />
            </form>
            <IconButton
            onClick={(e)=>onPageChange(e,page+1)}
            color='secondary'
            disabled={page >= Math.ceil(count/rowsPerPage) - 1}>
              <ChevronRightRoundedIcon />
            </IconButton>
            <IconButton
            onClick={(e)=>onPageChange(e, Math.max(0, Math.ceil(count/rowsPerPage)-1))}
            color='secondary'
            disabled={page >= Math.ceil(count/rowsPerPage) - 1}>
              <LastPageRoundedIcon />
            </IconButton>
          </div>
  )
}
export default TableActionsComponent