import React from "react";
import Button from "./Button";
import RenderInput from "../../utils/RenderInput";
import { useTheme } from "@mui/material/styles";

const FilterComponent = (props) => {
  const { fields = [], state, stateHandler, onFilter, onReset, fetchOptions } = props;
  const theme = useTheme();

  //   console.log("State", state);

  return (
    <div className=" mb-4 items-center mt-8">
      <div className="mr-12">
        <p className=" text-xl font-semibold" style={{ color: theme.palette.text.primary }}>
          Filters
        </p>
      </div>
      <div className="flex">
        <div className="flex items-end flex-wrap">
          {fields.map((item) => {
            return (
              <RenderInput
                key={item.id}
                item={{ ...item }}
                state={state}
                stateHandler={stateHandler}
                inputStyles={{ width: 240 }}
                containerClasses={"py-1 flex flex-col mr-4"}
                fetchOptions={fetchOptions}
              />
            );
          })}
        </div>
        <div className="flex items-end justify-center">
          <div className="flex flex-col gap-4 sm:flex-row">
          <Button title="Reset" variant="contained" sx={{ fontSize: 12 }} onClick={onReset} />
          <Button title="Filter" variant="contained" sx={{ fontSize: 12 }} onClick={onFilter} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterComponent;
