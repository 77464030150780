//TODO: This InventorySeller.jsx corresponds to Admin View, fix the file name, also for InventoryTableSeller.
import { useEffect, useState } from "react";
import InventoryTableSeller from "../Inventory/InventoryTableSeller";
import Button from "../../Shared/Button";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { getCall } from "../../../Api/axios";
import useCancellablePromise from "../../../Api/cancelRequest";
import { isObjEmpty } from "../../../utils/validations";
import { PRODUCT_CATEGORY } from "../../../utils/constants";
import { useTheme } from "@mui/material/styles";
import FilterComponent from "../../Shared/FilterComponent";
import cogoToast from "cogo-toast";
import { loadingView } from "../../Shared/LoadingView";

const columns = [
  {
    id: "provider_store",
    label: "Provider Store Name",
    minWidth: 120,
    // format: (value) => PRODUCT_CATEGORY[value] || value,
  },
  {
    id: "productSubcategory2",
    label: "Product Type",
    minWidth: 120,
    // format: (value) => PRODUCT_CATEGORY[value] || value,
  },
  { id: "productName", label: "Product Name", minWidth: 100 },
  {id:"productCode",label:"Product Number",minWidth:100},
  {
    id: "createdAt",
    label: "Created At",
    minWidth: 100,
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "updatedAt",
    label: "Updated At",
    minWidth: 100,
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "quantity",
    label: "Quantity",
    minWidth: 100,
    format: (value) => value.toLocaleString("en-US"),
  },
 
  // {
  //   id: "isCancellable",
  //   label: "Cancellable",
  //   boolean: true,
  //   minWidth: 100,
  // },
  // {
  //   id: "isReturnable",
  //   label: "Returnable",
  //   boolean: true,
  //   minWidth: 100,
  // },
  {
    id: "QCStatus",
    label: "QC Status",
    minWidth: 100,
  },
  {
    id: "QCDesc",
    label: "QC Comments",
    minWidth: 150,
  },

  {
    id: "published",
    label: "Published",
  },
];

export default function InventorySeller() {
  const theme = useTheme();
  const [page, setPage] = useState(() => {
    const savedPage = sessionStorage.getItem("inventorysellertablepage");
    return savedPage !== null ? parseInt(savedPage, 10) : 0
  });
  const [rowsPerPage, setRowsPerPage] = useState(() => {
    const savedRowsPerPage = sessionStorage.getItem("inventorysellertablerowsperpage");
    return savedRowsPerPage !== null ? parseInt(savedRowsPerPage, 10) : 25
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const navigate = useNavigate();
  const { cancellablePromise } = useCancellablePromise();
  const [products, setProducts] = useState([]);
  const [user, setUser] = useState(null); 
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    name: null,
    number: null,
    provider_store: null,
    category: "",
    stock: false,
    QCStatus:[],
    published:[]
  });
  const [nameFilterOptions, setNameFilterOptions] = useState([]);
  const [numberFilterOptions, setNumberFilterOptions] = useState([]);
  const [providerFilterOptions, setProviderFilterOptions] = useState([]);

  const filterFields = [
    {
      id: "provider_store",
      title: "",
      placeholder: "Search by Provider Store Name",
      type: "select-with-fetch",
      variant: "standard",
      options: providerFilterOptions
    },
    {
      id: "name",
      title: "",
      placeholder: "Search by Product Name",
      type: "select-with-fetch",
      variant: "standard",
      options: nameFilterOptions
    },
    {
      id: "number",
      title: "",
      placeholder: "Search by Product Number",
      type: "select-with-fetch",
      variant: "standard",
      options: numberFilterOptions
    },
    // {
    //   id: "category",
    //   title: "",
    //   placeholder: "Please Select Product Category",
    //   options: Object.entries(PRODUCT_CATEGORY).map(([key, value]) => {
    //     return { key: value, value: key };
    //   }),
    //   type: "select",
    //   variant: "standard",
    //   disableClearable: true,
    // },
    // {
    //   id: "stock",
    //   title: "Out of Stock",
    //   placeholder: "Please Select Product Category",
    //   type: "switch",
    //   containerClasses: "flex items-center",
    //   styles: {
    //     marginLeft: 2,
    //   },
    // },
    {
      id: "QCStatus",
      title: "",
      placeholder: "QC Status",
      options: [
        {key:"In Progress",value:"In Progress"},
        {key:"Approved",value:"Approved"},
        {key:"Rejected",value:"Rejected"},
      ],
      
      type: "select-multiple",
      variant: "standard",
      multiple: true
    },
    {
      id: "published",
      title: "",
      placeholder: "Published",
      type: "select-multiple",
      options: [
        {key:"Yes", value:"true"},
        {key:"No", value:"false"}
      ],
      variant: "standard",
      multiple: true
    },
  ];

  const getProducts = async () => {
    try {
      const user_id = localStorage.getItem("user_id");
      const user = await getUser(user_id);

      let url;
      if (user.role.name === "Super Admin") {
        url = `/api/v1/AdminList?limit=${rowsPerPage}&offset=${page}`;
      } else {
        url = `/api/v1/products?limit=${rowsPerPage}&offset=${page}`;
      }
      const res = await cancellablePromise(getCall(url));
      setProducts(res.data);
      setTotalRecords(res.count);
      setLoading(false);
    } catch (error) {
      cogoToast.error("Something went wrong!");
      setLoading(false);
    }
  };

  const getOrgDetails = async (org_id) => {
    const url = `/api/v1/organizations/${org_id}/storeDetails`;
    const res = await getCall(url);
    return res;
  };

  const getUser = async (id) => {
    const url = `/api/v1/users/${id}`;
    const res = await getCall(url);
    setUser(res[0]);
    return res[0];
    
  };

  useEffect(() => {
    const user_id = localStorage.getItem("user_id");
    getUser(user_id).then((u) => {
      // roles - Organization Admin, Super Admin
      console.log("User:", u);
      if (u.isSystemGeneratedPassword) navigate("/initial-steps");
      getOrgDetails(u.organization);
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    getProducts();
  }, [page, rowsPerPage]);

  useEffect(()=>{
    onFilter();
    if(!filters.name)
      setNameFilterOptions([]);
    if(!filters.number)
      setNumberFilterOptions([]);
    if(!filters.provider_store)
      setProviderFilterOptions([]);
  }, [filters]);

  const fetchOptions = async(search_text, property)=> {
    if(search_text?.length>0){
      const res = await cancellablePromise(getCall(`/api/v1/AdminList?${property}=${encodeURIComponent(search_text)}`));
      if(property==='name')
        setNameFilterOptions([...new Set(
          res.data.map((item)=>(
          item.productName)))]);
      else if(property==='number')
        setNumberFilterOptions([...new Set(
          res.data.map((item)=>(
          item.productCode)))]);
      else if(property==='provider_store')
        setProviderFilterOptions([...new Set(
          res.data.map((item)=>(
          item.organization.name)))]);
    } else {
      property === 'name' && setNameFilterOptions([]);
      property === 'number' && setNumberFilterOptions([]);
      property === 'provider_store' && setProviderFilterOptions([]);
    }
  }

  const handleRefresh = (data) => {
    getProducts();
  };  

  const onReset = () => {
    setFilters({ name: null, number: null, provider_store: null, category: null, stock: false, QCStatus: [], published: [] });
    getProducts();
  };

  const onFilter = async () => {
    const filterParams = [];
    if (filters.name != undefined && filters.name?.trim() !== "") {
      filterParams.push(`name=${encodeURIComponent(filters.name)}`);
    }
    
    if (filters.number != undefined && filters.number !== "") {
      filterParams.push(`number=${encodeURIComponent(filters.number)}`);
    }

    if (filters.provider_store != undefined && filters.provider_store !== "") {
      filterParams.push(`provider_store=${encodeURIComponent(filters.provider_store)}`);
    }

    if (filters.category != undefined && filters.category !== "") {
      filterParams.push(`category=${encodeURIComponent(filters.category)}`);
    }

    // if (!filters.stock) {
    //   filterParams.push(`stock=inStock`);
    // } else {
    //   filterParams.push(`stock=outOfStock`);
    // }

    if(filters.QCStatus.length>0){
      console.log('status', filters.QCStatus)
      filterParams.push(`QCStatus=${JSON.stringify(filters.QCStatus.map(item=>item.value))}`);
    }

    if(filters.published.length>0){
      filterParams.push(`published=${JSON.stringify(filters.published.map(item=>item.value))}`);
    }

    const queryString = filterParams.join("&");
    const url = `/api/v1/AdminList?${queryString}`;

    const res = await cancellablePromise(getCall(url));
    setProducts(res.data);
    console.log("Res Data",res.data);
    setTotalRecords(res.count);
  };

  const handlePageChange = (val) => {
    setPage(val);
    setLoading(true);
    sessionStorage.setItem("inventorysellertablepage", val);
  }

  const handleRowsPerPageChange = (val) => {
    setRowsPerPage(val);
    sessionStorage.setItem("inventorysellertablerowsperpage", val);
  }

  //TODO: add consistency in styles across browsers for each page
  return (
    <>
      { loading ? loadingView : ( <div className="container mx-auto my-8 px-2 md:px-8">
        <div className="mb-4 flex flex-row justify-between items-center">
          <label
            style={{ color: theme.palette.text.primary }}
            className="font-semibold text-2xl"
          >
             {user && user.role && user.role.name === "Super Admin" ? "Product Listings" : "Inventory"}
          </label>
          <div className="flex">
            {user && user.role && user.role.name !== "Super Admin" && (
              <div style={{ marginRight: 15 }}>
                <Button
                  variant="contained"
                  icon={<AddIcon />}
                  title="Bulk upload"
                  onClick={() => navigate("/application/bulk-upload")}
                />
              </div>
            )}
            {user && user.role && user.role.name !== "Super Admin" && (
              <Button
                variant="contained"
                icon={<AddIcon />}
                className=""
                title="ADD PRODUCT"
                onClick={() => navigate("/application/add-products")}
              />
            )}
          </div>
        </div>
        <FilterComponent
          fields={filterFields}
          state={filters}
          stateHandler={setFilters}
          onReset={onReset}
          onFilter={onFilter}
          fetchOptions={fetchOptions}
        />
        <InventoryTableSeller
          columns={columns}
          data={products}
          onRefresh={handleRefresh}
          totalRecords={totalRecords}
          page={page}
          rowsPerPage={rowsPerPage}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
          getProducts ={getProducts}
          user={user}
        />
      </div> )}
    </>
  );
}
