import * as React from "react";
import {
  Box,
  SwipeableDrawer,
  List,
  Divider,
  ListItem,
  ListItemButton,
  ListItemText,
  Collapse,
  Stack,
  Typography,
} from "@mui/material";
import logo from "../../Assets/Images/logo-aurika.png";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import LogoutIcon from "@mui/icons-material/Logout";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { deleteAllCookies } from "../../utils/cookies";
import { getCall, postCall } from "../../Api/axios";
import { useTheme } from "@mui/material/styles";

export default function Sidebar(props) {
  const navigate = useNavigate();
  const [user, setUser] = React.useState();
  const [state, setState] = React.useState({
    left: false,
  });
  const [category, setCategory] = React.useState("");
  const theme = useTheme();

  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const getUser = async (id) => {
    const url = `/api/v1/users/${id}`;
    const res = await getCall(url);
    setUser(res[0]);
    return res[0];
  };

  const getOrgDetails = async (org_id) => {
    const url = `/api/v1/organizations/${org_id}/storeDetails`;
    const res = await getCall(url);
    return res;
  };

  React.useEffect(() => {
    const user_id = localStorage.getItem("user_id");
    getUser(user_id).then((userData) => {
      getOrgDetails(userData.organization).then((org) => {
        setCategory(org?.storeDetails?.category);
      })
    })
  }, []);

  React.useEffect(() => {
    if (props.open) {
      setState({ left: true });
    } else {
      setState({ left: false });
    }
  }, [props.open, props.setOpen]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setState({ ...state, [anchor]: open });
    props.setOpen(false);
  };

  async function logout() {
    if (window.confirm("Are you sure you want to logout your session?")) {
      await postCall(`/api/v1/auth/logout`);
      deleteAllCookies();
      localStorage.clear();
      sessionStorage.clear();
      navigate("/");
    }
  }

  const list = (anchor) => {
    return <Box
    sx={{
      width: anchor === "top" || anchor === "bottom" ? "auto" : 250, backgroundColor: theme.palette.primary.main
    }}
      role="presentation"
    >
      <Stack
        direction="row"
        alignItems="center"
        style={{ padding: "8px 16px" }}
      >
        <div className="overflow-hidden w-40 h-14 relative"><img src={logo} alt="logo" className="w-40 absolute object-cover top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" /></div>
        <Typography sx={{fontSize: "13px", fontFamily: "serif", marginLeft:"-12px", marginTop:"8px", zIndex:"10"}}>Seller App</Typography>
      </Stack>
      <Divider />
      <List
        sx={{
          width: "100%",
          maxWidth: 360,
          bgcolor: "background.paper",
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <ListItemButton onClick={handleClick}>
          <ListItemText primary="Dashboard" />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse
          in={open}
          timeout="auto"
          unmountOnExit
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
        >
          <List component="div" disablePadding>
          {user?.role?.name === "Super Admin" && (
              <NavLink
                to="/product-listings"
                className="no-underline	text-black"
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Product Listings" />
                </ListItemButton>
              </NavLink>
            )}
            {user?.role?.name === "Organization Admin" && (
              <NavLink
                to="/application/inventory"
                className="no-underline text-black"
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Inventory" />
                </ListItemButton>
              </NavLink>
            )}
            {/* {user?.role?.name === "Organization Admin" && (
                <NavLink  to={{
                    pathname: `/application/menu-category/${category}`,
                  }}
                   className="no-underline text-black">
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemText primary="Custom Menu" />
                  </ListItemButton>
                </NavLink>
            )} */}
            {user?.role?.name === "Super Admin" && (
              <NavLink
                to="/add-gold-rates"
                className="no-underline	text-black"
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Add Gold Rates" />
                </ListItemButton>
              </NavLink>
            )}
              <NavLink
                to="/application/orders"
                className="no-underline	text-black"
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Orders" />
                </ListItemButton>
              </NavLink>
              <NavLink
                to="/application/returns"
                className="no-underline	text-black"
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Returns" />
                </ListItemButton>
              </NavLink>

              <NavLink
                to="/application/complaints"
                className="no-underline text-black"
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Complaints" />
                </ListItemButton>
              </NavLink>
              <NavLink
                  to={{
                    pathname: `/application/offers`,
                  }}
                  className="no-underline text-black"
                >
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemText primary="Offers" />
                  </ListItemButton>
                </NavLink>
            {user?.role?.name === "Organization Admin" && (
              <NavLink
                to={{
                  pathname: `/application/store-details/${user?.organization}`,
                }}
                className="no-underline text-black"
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Store Details" />
                </ListItemButton>
              </NavLink>
            )}
            {user?.role?.name === "Organization Admin" && (
              <NavLink
                to={{
                  pathname: `/profile/${user?.organization}`,
                }}
                className="no-underline text-black"
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Seller Profile" />
                </ListItemButton>
              </NavLink>
            )}
            {user?.role?.name === "Super Admin" && (
              <NavLink
                to="/settlements"
                className="no-underline	text-black"
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Settlements" />
                </ListItemButton>
              </NavLink>
            )}
            {user?.role?.name === "Super Admin" && (
              <NavLink
                to="/receiver-recon-requests"
                className="no-underline	text-black"
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Receiver Recon Requests" />
                </ListItemButton>
              </NavLink>
            )}
            {user?.role?.name === "Super Admin" && (
              <NavLink
                to="/application/user-listings"
                className="no-underline	text-black"
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="User Listings" />
                </ListItemButton>
              </NavLink>
            )}
          </List>
        </Collapse>
      </List>
      <List
        style={{ position: "absolute", bottom: "0" }}
        className="w-full flex-row"
      >
        <ListItem key="Log Out" disablePadding>
          <ListItemButton onClick={() => logout()}>
            <LogoutIcon />
            <ListItemText primary="Log Out" className="mx-4" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  };
  
  return (
    <div>
      <React.Fragment key="left">
        <SwipeableDrawer
          anchor="left"
          open={state["left"]}
          onClose={toggleDrawer("left", false)}
          onOpen={toggleDrawer("left", true)}
        >
          {list("left")}
        </SwipeableDrawer>
        <Outlet />
      </React.Fragment>
    </div>
  );
}
