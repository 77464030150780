import { useEffect, useState } from "react";
import cogoToast from "cogo-toast";
import moment from "moment";
import MyButton from "../../Shared/Button";
import { useNavigate, useParams } from "react-router-dom";
import { getCall, postCall, putCall } from "../../../Api/axios";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import OfferInfo from "./OfferInfo";
import OfferBenefit from "./OfferBenefit";
import OfferQualifier from "./OfferQualifier";
import BackNavigationButton from "../../Shared/BackNavigationButton";
import { loadingView } from "../../Shared/LoadingView";

const AddOffer = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [isEdit, setIsEdit] = useState(params?.id ? true:false);
  const [tabErrors, setTabErrors] = useState([false, false, false]);
  const [offerType, setOfferType] = useState("discount");

  const [offerInfoForm, setOfferInfoForm] = useState({});
  const [offerInfoFormErrors, setOfferInfoFormErrors] = useState({});

  const [offerQualifierForm, setOfferQualifierForm] = useState({});
  const [offerQualifierFormErrors, setOfferQualifierFormErrors] = useState({});

  const [offerBenefitForm, setOfferBenefitForm] = useState({});
  const [offerBenefitFormErrors, setOfferBenefitFormErrors] = useState({});
  const [tabValue, setTabValue] = useState("1");
  const [isLoading, setIsLoading] = useState(true);

  const theme = useTheme();

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    let offerId = params?.id;
    if (offerId) {
      getOfferDetails(offerId);
    }
  }, []);

  const getOfferDetails = async (id) => {
    try {
      const url = `/api/v1/offers?_id=${id}`;
      const res = await getCall(url);
      setOfferType(res?.data[0]?.type);
      console.log("offer res->", res?.data);
      // Extract offer data from the response
      const {
        type,
        offerId,
        description,
        autoApply,
        additive,
        validFrom,
        validTo,
        items,
        images,
        qualifiers,
        benefits,
      } = res?.data[0];

      // Update offer info form fields
      setOfferInfoForm({
        type,
        offerId,
        description,
        autoApply,
        additive,
        validFrom,
        validTo,
        items,
        images,
      });

      // Update offer qualifier form fields
      setOfferQualifierForm({
        minValue: qualifiers.minValue,
        itemCount: qualifiers.itemCount,
        // Add other qualifier fields as needed
      });

      // Update offer benefit form fields
      benefits && setOfferBenefitForm({
        valueType: benefits.valueType,
        value: benefits.value,
        valueCap: benefits.valueCap,
        itemCount: benefits.itemCount,
        item: benefits.item,
        itemValue: benefits.itemValue,
        // Add other benefit fields as needed
      });
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  useEffect(()=>{
    if(!isEdit){
      setOfferBenefitForm({});
      setOfferQualifierForm({});
    }
  }, [offerType]);

  const handleSubmit = async () => {
    // Gather data from all three components
    const isValid = offerValidation();
    if (!isValid) {
      console.log("validation failed");
      cogoToast.error("Please fill in all required fields appropriately!");
      return;
    }
    const offerInfoData = offerInfoForm;
    const offerQualifierData = offerQualifierForm;
    const offerBenefitData = offerBenefitForm;
    // const validFromTimestamp = new Date(offerInfoData.validFrom).getTime();
    // const validToTimestamp = new Date(offerInfoData.validTo).getTime();
    // Combine data from all components
    if(offerBenefitData?.valueType==='amount'){
      delete offerBenefitData?.valueCap;
    }
    const formData = {
      type: offerType,
      offerId: offerInfoData.offerId,
      description: offerInfoData.description,
      validFrom: offerInfoData.validFrom,
      validTo: offerInfoData.validTo,
      autoApply: offerInfoData.autoApply,
      additive: offerInfoData.additive,
      images: offerInfoData.images,
      items: offerInfoData.items,
      qualifiers: offerQualifierData,
      benefits: offerBenefitData,
    };
    console.log("form data being sent->", formData);
    try {
        // Make API call to submit form data
        let response = ''
        if (params?.id) {
            response = await putCall(`/api/v1/offers?id=${params?.id}`, formData);
            cogoToast.success("Offer Updated Succesfully");
        } else {
            response = await postCall('/api/v1/offers', formData);
            cogoToast.success("Offer Added Succesfully");
        }
        navigate('/application/offers');
    } catch (error) {
      console.log('error->', error);
      cogoToast.error(error?.response?.data?.msg);
    }
  };

  //validate offer info form
  const validateOfferInfo = () => {
    let infoFormErrors = {};
    infoFormErrors.offerType = (offerType === undefined || offerType === "")
                                ? 'Offer type is required' : '';
    infoFormErrors.offerId =
      (offerInfoForm.offerId === undefined || offerInfoForm.offerId === "")
                                  ? 'Offer id is required' : '';
    infoFormErrors.description =
      (offerInfoForm.description === undefined ||
      offerInfoForm.description === "")
        ? 'Offer description is required' : '';
    infoFormErrors.autoApply =
      (offerInfoForm.autoApply !== "true" && offerInfoForm.autoApply !== "false")
        ? 'Choose if the offer can be auto applied' : '';
    infoFormErrors.additive =
      (offerInfoForm.additive !== "true" && offerInfoForm.additive !== "false")
        ? 'Choose if the offer can be combined with other offers' : '';
    infoFormErrors.validFrom =
      (offerInfoForm.validFrom === undefined ||
      offerInfoForm.validFrom === "" ||
      isNaN(new Date(offerInfoForm.validFrom).getTime()))
        ? 'required' : '';
    infoFormErrors.validTo =
      (offerInfoForm.validTo === undefined ||
      offerInfoForm.validTo === "" ||
      isNaN(new Date(offerInfoForm.validTo).getTime()))
        ? 'required' : '';
    infoFormErrors.validity = (new Date(offerInfoForm.validTo) <= new Date(offerInfoForm.validFrom))
                                ? 'Please provide proper dates for valdity' : '';
    // infoFormErrors.images = offerInfoForm.images === undefined || offerInfoForm.images?.length < 1;
    // infoFormErrors.items = offerInfoForm.items === undefined || offerInfoForm.items?.length < 1;
    console.log("validation->>>> infoFormErrors", infoFormErrors);
    setOfferInfoFormErrors(infoFormErrors);
    return !Object.values(infoFormErrors).some(val=>val!=='');
  };
  //validate offer qualifier form
  const validateOfferQualifier = () => {
    let qualifierFormErrors = {};
    if (["discount", "freebie", "delivery"].includes(offerType)) {
      qualifierFormErrors.minValue =
        (offerQualifierForm.minValue === undefined ||
        offerQualifierForm.minValue === "") ? 'Min Value is required' : '';
    }
    if (offerType === "buyXgetY") {
      qualifierFormErrors.itemCount =
        (offerQualifierForm.itemCount === undefined ||
        offerQualifierForm.itemCount === "") ? 'Item Count is required' : '';
    }
    console.log("validation->>>> qualifierFormErrors", qualifierFormErrors);
    setOfferQualifierFormErrors(qualifierFormErrors);
    return !Object.values(qualifierFormErrors).some(val=>val!=='');
  };
  //validate offer benefit form
  const validateOfferBenefit = () => {
    let benefitFormErrors = {};
    if (offerType === "discount") {
      benefitFormErrors.valueType =
        (offerBenefitForm.valueType === undefined ||
        offerBenefitForm.valueType === "") ? 'Value type is required' : '';
      benefitFormErrors.value =
        (offerBenefitForm.value === undefined || offerBenefitForm.value === "")
          ? `Discount ${offerBenefitForm?.valueType || "amount"} is required` : '';
      benefitFormErrors.valueCap =
        offerBenefitForm.valueType === "percentage" &&
        (offerBenefitForm.valueCap === undefined ||
          offerBenefitForm.valueCap === "")
          ? 'Max discount amount is required' : '';
    }
    if (offerType === "buyXgetY") {
      benefitFormErrors.itemCount =
        (offerBenefitForm.itemCount === undefined ||
        offerBenefitForm.itemCount === "")
          ? 'Extra Item Count is required' : '';
      benefitFormErrors.item =
        (offerBenefitForm.item === undefined ||
        Object.keys(offerBenefitForm.item)?.length < 1)
          ? 'Extra Item in offer is required' : '';
    }
    if (offerType === "freebie") {
      benefitFormErrors.itemCount =
        (offerBenefitForm.itemCount === undefined ||
        offerBenefitForm.itemCount === "")
          ? 'Free Item Count is required' : '';
      benefitFormErrors.item =
        (offerBenefitForm.item === undefined ||
        Object.keys(offerBenefitForm.item)?.length < 1)
          ? 'Free Item in offer is required' : '';
      benefitFormErrors.itemValue =
        (offerBenefitForm.itemValue === undefined ||
        offerBenefitForm.itemValue === "")
          ? 'Approximate value for free item is required' : '';
    }
    console.log("validation->>>> benefitFormErrors", benefitFormErrors);
    setOfferBenefitFormErrors(benefitFormErrors);
    return !Object.values(benefitFormErrors).some(val=>val!=='');
  };

  const offerValidation = () => {
    const offerInfoValidity = validateOfferInfo();
    const offerQualifierValidity = validateOfferQualifier();
    const offerBenefitValidity = validateOfferBenefit();

    setTabErrors((prev_state) => {
      prev_state[0] = !offerInfoValidity;
      prev_state[1] = !offerQualifierValidity;
      prev_state[2] = !offerBenefitValidity;
      return [...prev_state];
    });

    return offerInfoValidity && offerQualifierValidity && offerBenefitValidity;
  };

  const renderOfferInfo = () => {
    return (
      <OfferInfo
        formData={offerInfoForm}
        onFormUpdate={setOfferInfoForm}
        offerInfoFormErrors={offerInfoFormErrors}
        setOfferType={setOfferType}
        offerType={offerType}
        isEdit={isEdit}
      />
    );
  };

  const renderOfferQualifier = () => {
    return (
      <OfferQualifier
        formData={offerQualifierForm}
        onFormUpdate={setOfferQualifierForm}
        offerQualifierFormErrors={offerQualifierFormErrors}
        offerType={offerType}
      />
    );
  };

  const renderOfferBenefit = () => {
    return (
      <OfferBenefit
        formData={offerBenefitForm}
        onFormUpdate={setOfferBenefitForm}
        offerBenefitFormErrors={offerBenefitFormErrors}
        offerType={offerType}
      />
    );
  };

  return (
    <>
      <div className="container mx-auto my-8 px-2 md:px-8">
        {isEdit && isLoading ? loadingView : (<div
          className="w-full bg-white px-4 py-4 rounded-md h-full scrollbar-hidden"
          style={{ minHeight: "95%", maxHeight: "100%", overflow: "auto" }}
        >
          <BackNavigationButton
            onClick={() => navigate("/application/offers")}
          />
          <div className="w-full !h-full">
            <label className="ml-2 md:mb-4 md:mt-3 mt-2 font-semibold text-xl">
              {isEdit ? 'Edit Offer' : 'Add Offer'}
            </label>
            <form>
              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={tabValue}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      values={tabValue}
                      onChange={handleTabChange}
                      sx={{
                        "& .MuiTabs-indicator": {
                          backgroundColor: tabErrors[tabValue - 1]
                            ? "red"
                            : theme.palette.tertiary.main,
                        },
                        "& .MuiTab-root.Mui-selected": {
                          color: tabErrors[tabValue - 1]
                            ? "red"
                            : theme.palette.tertiary.main,
                          fontWeight: 700,
                        },
                      }}
                      centered
                    >
                      <Tab
                        sx={{
                          color:
                            tabErrors[0] &&
                            Object.keys(offerInfoFormErrors).length > 0
                              ? "red"
                              : "none",
                        }}
                        label="Offer Info"
                        value="1"
                      />
                      <Tab
                        sx={{
                          color:
                            tabErrors[1] &&
                            Object.keys(offerQualifierFormErrors).length > 0
                              ? "red"
                              : "none",
                        }}
                        label="Offer Qualifier"
                        value="2"
                      />
                      {offerType != "delivery" && <Tab
                        sx={{
                          color:
                            tabErrors[2] &&
                            Object.keys(offerBenefitFormErrors).length > 0
                              ? "red"
                              : "none",
                        }}
                        label="Offer Benefit"
                        value="3"
                      />}
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    <div className="mt-2">{renderOfferInfo()}</div>
                  </TabPanel>
                  <TabPanel value="2">
                    <div className="mt-2">{renderOfferQualifier()}</div>
                  </TabPanel>
                  <TabPanel value="3">{renderOfferBenefit()}</TabPanel>
                </TabContext>
              </Box>

              <div className="flex flex-row justify-center py-2 sm:pt-5 md:!mt-10">
                <MyButton
                  type="button"
                  title={params?.id ? "UPDATE Offer" : "ADD Offer"}
                  variant="contained"
                  className="!ml-5"
                  onClick={handleSubmit}
                />
              </div>
            </form>
          </div>
        </div>)}
      </div>
    </>
  );
};
export default AddOffer;
