import React, { useState } from "react";
import CrossIcon from "../../Shared/svg/cross-icon";
import Button from "../../Shared/Button";
import styles from "../../../Styles/actionCard.module.scss"
import { postCall } from "../../../Api/axios";
import cogoToast from "cogo-toast";
import { ONDC_COLORS } from "../../Shared/Colors";
import { TextField } from "@mui/material";
import styled from "@emotion/styled";

const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "black",
    },
    "&:hover fieldset": {
      borderColor: "#1c75bc",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#1c75bc",
    },
  },
});

export default function CustomerCascadedCard({
  supportActionDetails,
  onClose,
  onSuccess,
  user
}) {

  // STATES
  const [loading, setLoading] = useState(false);
  const [shortDescription, setShortDescription] = useState("");
  const [longDescription, setLongDescription] = useState("");


  async function onSubmit() {
    
    setLoading(true);
    const { context } = supportActionDetails;

    const body = {
      "context": context,
      "respondent_action": "CASCADED" ,
      "action_triggered" : "CASCADED",
      "short_desc": shortDescription,
      "long_desc": longDescription,
      "updated_by": {
        "org": {
          "name": user?.organization
        },
        "contact": {
          "phone": user?.mobile,
          "email": user?.email
        },
        "person": {
          "name": user?.name
        }
      }
    }

    postCall(`/api/client/issue_response`, body)
      .then((resp) => {
        setLoading(false)
        if (resp.success && resp.success === true) {
          onSuccess(context.transaction_id)
        } else {
          cogoToast.error(resp.message);
        }
      })
      .catch((error) => {
        setLoading(false)
        console.log(error);
        cogoToast.error(error.response.data.error);
      });
  }

  return (
    <div className={styles.overlay}>
      <div className={styles.popup_card}>
        <div className={`${styles.card_header} display: flex`} style={{justifyContent: 'space-between'}}>
          <p className={styles.card_header_title}>Take Action</p>
          <div className="ms-auto">
            <CrossIcon
              width="20"
              height="20"
              color={ONDC_COLORS.SECONDARYCOLOR}
              style={{ cursor: "pointer" }}
              onClick={onClose}
            />
          </div>
        </div>

        <div className={styles.card_body}>

          <div className="py-1 flex flex-col">
            <label className="text-sm py-2 ml-1 font-medium text-left text-[#606161] inline-block">
              Short Description
              <span className="text-[#FF0000]"> *</span>
            </label>
            <CssTextField
              type={"input"}
              className="w-full h-full px-2.5 py-3.5 text-[#606161] bg-transparent !border-black"
              required={true}
              size="small"
              autoComplete="off"
              placeholder={"Short description"}
              value={shortDescription}
              onChange={(e) =>
                setShortDescription(e.target.value)
              }
              inputProps={{
                maxLength: 50
              }}
            />
            <label className="text-sm py-2 ml-1 font-medium text-left text-[#606161] inline-block">
              Long Description
            </label>
            <CssTextField
              type={"input"}
              className="w-full h-full px-2.5 py-3.5 text-[#606161] bg-transparent !border-black"
              size="small"
              autoComplete="off"
              placeholder={"Long description"}
              value={longDescription}
              onChange={(e) =>
                setLongDescription(e.target.value)
              }
            />
          </div>

        </div>

        <div
          className={`${styles.card_footer}`}
        >
          <Button
            type="button"
            disabled={loading}
            title="Cancel"
            color="tertiary"
            onClick={() => onClose()}
          />
          <Button
            disabled={loading}
            type="button"
            title="Submit"
            variant="contained"
            className="!ml-5"
            onClick={() =>  onSubmit()}
          />
        </div>
      </div>
    </div>
  );
}
