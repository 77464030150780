import { useState, useEffect } from "react";
import { getCall } from "../../../Api/axios";
import { useTheme } from '@mui/material/styles';
import ComplaintTable from "./ComplaintTable";
import { loadingView } from "../../Shared/LoadingView";

const columns = [
  {
    id: "provider_name",
    label: "Provider Store Name",
    format: (value) => value.toLocaleString("en-US"),
    minWidth: 130,
    align: "center",
  },
  {
    id: "issueId",
    label: "Issue Id",
    minWidth: 120,
    align: "center"
  },
  {
    id:"productNames",
    label: "Product Name",
    minWidth: 120,
    align: "center"
  },
  {
    id: "status",
    label: "Status",
    format: (value) => value.toLocaleString("en-US"),
    minWidth: 140,
    align: "center",
  },
  {
    id: "created_at",
    label: "Created On",
    minWidth: 180,
    format: (value) => value.toLocaleString("en-US"),
    align: "center",
  },
  {
    id: "category",
    label: "Issue Type",
    format: (value) => value.toLocaleString("en-US"),
    minWidth: 130,
    align: "center",
  },
  {
    id: "sub_category",
    label: "Issue Subcategory",
    format: (value) => value.toLocaleString("en-US"),
    minWidth: 100,
    align: "center",
  },
  {
    id: "short_description",
    label: "Short Description",
    format: (value) => value.toLocaleString("en-US"),
    minWidth: 170,
    align: "center",
  },
  {
    id: "updated_at",
    label: "Last Updated On",
    minWidth: 180,
    format: (value) => value.toLocaleString("en-US"),
    align: "center",
  },
  {
    id: "action",
    label: "Action",
    format: (value) => value.toLocaleString("en-US"),
    minWidth: 100,
    align: "center",
  },
];

export default function Complaints() {
  const theme = useTheme();
  const [complaints, setComplaints] = useState([]);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [page, setPage] = useState(() => {
    const savedPage = sessionStorage.getItem("complaintstablepage");
    return savedPage !== null ? parseInt(savedPage, 10) : 0
  });
  const [rowsPerPage, setRowsPerPage] = useState(() => {
    const savedRowsPerPage = sessionStorage.getItem("complaintstablerowsperpage");
    return savedRowsPerPage !== null ? parseInt(savedRowsPerPage, 10) : 25
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [columnList, setColumnList] = useState(columns);
  const [loading, setLoading] = useState(true);

  const getAllComplaints = async () => { 
    const url = `/api/client/all-issue?limit=${rowsPerPage}&offset=${page}`;
    getCall(url)
      .then((resp) => {
        setComplaints(resp.issues);
        setTotalRecords(resp.count);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.response);
        setLoading(false);
      });
   }

  useEffect(() => {
    setLoading(true);
    getAllComplaints();

    if(user && user?.role?.name === "Organization Admin"){
      const filteredColumns = columns.filter((column) => column.id !== "provider_name");
      setColumnList(filteredColumns);

      const filteredComplaints = complaints.filter((complaint) => complaint.message.issue.order_details.provider_id === user.organization);
      setComplaints(filteredComplaints);
    }
  }, [user, page, rowsPerPage]);

    // empty state ui
    const empty_orders_state = (
      <div
          className={`d-flex align-items-center justify-content-center`}
      >
          <div className="text-center">
              <div className="py-2">
                  <p>No Complaints found!</p>
              </div>
          </div>
      </div>
  );

  const handlePageChange = (val) => {
    setPage(val);
    setLoading(true);
    sessionStorage.setItem("complaintstablepage", val);
  }

  const handleRowsPerPageChange = (val) => {
    setRowsPerPage(val);
    sessionStorage.setItem("complaintstablerowsperpage", val);
  }
  
  return (
    <>
      { loading ? loadingView : ( <div className="container mx-auto my-8 px-2 md:px-8">
        <div className="mb-4 flex flex-row justify-between items-center">
          <label style={{ color: theme.palette.text.primary }} className="font-semibold text-2xl">Complaints</label>
        </div>
        { complaints?.length > 0 ?
         <ComplaintTable
          columns={columnList}
          data={complaints}
          totalRecords={totalRecords}
          page={page}
          user={user}
          rowsPerPage={rowsPerPage}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
          onSuccess={() => getAllComplaints()}
        />
        :
        empty_orders_state
       }
      </div> )}
    </>
  );
}
